import React, { useState } from 'react';
import { Box, Typography } from '@heycater/design-system';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import Image from 'next/image';
import { useTranslation } from 'react-i18next';
import Slider from 'react-slick';
import styled from 'styled-components';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import EytestimonialLogo from '@images/homepage/eytestimoniallogo.png';
import SumuptestimonialLogo from '@images/homepage/sumuptestimoniallogo.png';
import TaxfixtestimonialLogo from '@images/homepage/taxfixtestimoniallogo.png';
import { useBreakpointDown } from 'qualification/hooks/useBreakpoints';

export default function Testimonials() {
  const [_sliderRef, setSliderRef] = useState(null);
  const [currentTab, setCurrentTab] = useState(0);
  const { t } = useTranslation('homepage');
  const mobile = useBreakpointDown('sm');

  const slides = [
    {
      logo: {
        src: TaxfixtestimonialLogo,
        width: 164,
        height: 67,
      },
      left: {
        headline: t('testimonials.slide1.left.headline'),
        review: t('testimonials.slide1.left.review'),
        name: t('testimonials.slide1.left.name'),
        title: t('testimonials.slide1.left.title'),
      },
      right: {
        top: {
          heading: t('testimonials.slide1.right.top.heading'),
          subtitle: t('testimonials.slide1.right.top.subtitle'),
        },
        bottom: {
          heading: t('testimonials.slide1.right.bottom.heading'),
          subtitle1: t('testimonials.slide1.right.bottom.subtitle1'),
          subtitle2: t('testimonials.slide1.right.bottom.subtitle2'),
        },
      },
    },
    {
      logo: {
        src: SumuptestimonialLogo,
        width: 151,
        height: 45,
      },
      left: {
        headline: t('testimonials.slide2.left.headline'),
        review: t('testimonials.slide2.left.review'),
        name: t('testimonials.slide2.left.name'),
        title: t('testimonials.slide2.left.title'),
      },
      right: {
        top: {
          heading: t('testimonials.slide2.right.top.heading'),
          subtitle: t('testimonials.slide2.right.top.subtitle'),
        },
        bottom: {
          heading: t('testimonials.slide2.right.bottom.heading'),
          subtitle1: t('testimonials.slide2.right.bottom.subtitle1'),
          subtitle2: t('testimonials.slide2.right.bottom.subtitle2'),
        },
      },
    },
    {
      logo: {
        src: EytestimonialLogo,
        width: 141,
        height: 67,
      },
      left: {
        headline: t('testimonials.slide3.left.headline'),
        review: t('testimonials.slide3.left.review'),
        name: t('testimonials.slide3.left.name'),
        title: t('testimonials.slide3.left.title'),
      },
      right: {
        top: {
          heading: t('testimonials.slide3.right.top.heading'),
          subtitle: t('testimonials.slide3.right.top.subtitle'),
        },
        bottom: {
          heading: t('testimonials.slide3.right.bottom.heading'),
          subtitle1: t('testimonials.slide3.right.bottom.subtitle1'),
          subtitle2: t('testimonials.slide3.right.bottom.subtitle2'),
        },
      },
    },
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (oldIndex: any, newIndex: any) => setCurrentTab(newIndex),
    prevArrow: <BackIcon />,
    nextArrow: <NextIcon />,
    arrows: !mobile,
    customPaging: (i: any) => (
      <div className={`custom-dot ${currentTab === i ? 'active' : ''}`}>
        <IndicatorIcon
          style={{
            fill: currentTab === i ? '#3C4340' : '#E3E4E4',
          }}
        />
      </div>
    ),
    appendDots: (dots: any) => (
      <div style={{ bottom: mobile ? '-40px' : '-40px' }}>
        <ul style={{ margin: '0px', padding: 0 }}> {dots} </ul>
      </div>
    ),
  };
  return (
    <Wrapper>
      <Title>Erstklassige Unternehmen vertrauen uns seit Jahren</Title>
      <SliderWrapper>
        <Slider ref={(slider: any) => setSliderRef(slider)} {...settings}>
          {slides.map((card, index) => (
            <Slide key={index}>
              <TestimonialLeftBox>
                <Card>
                  <ImageBox>
                    <Image
                      src={card.logo.src}
                      width={card.logo?.width}
                      height={card.logo?.height}
                      quality={100}
                    />
                  </ImageBox>
                  <Subtitle>{card.left.headline}</Subtitle>
                  <Review>{card.left.review}</Review>
                  <Box>
                    <ContactName>{card.left.name}</ContactName>
                    <ContactTitle>{card.left.title}</ContactTitle>
                  </Box>
                </Card>
              </TestimonialLeftBox>
              {mobile ? (
                <>
                  <TopAccordion>
                    <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Head>
                        <Typography>{card.right.top.heading}</Typography>
                      </Head>
                    </StyledAccordionSummary>
                    <StyledAccordionDetails>
                      <Paragraph>{card.right.top.subtitle}</Paragraph>
                    </StyledAccordionDetails>
                  </TopAccordion>
                  <BottomAccordion>
                    <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Head>
                        <Typography>{card.right.bottom.heading}</Typography>
                      </Head>
                    </StyledAccordionSummary>
                    <StyledAccordionDetails>
                      <Paragraph>{card.right.bottom.subtitle1}</Paragraph>
                      <Paragraph>{card.right.bottom.subtitle2}</Paragraph>
                    </StyledAccordionDetails>
                  </BottomAccordion>
                </>
              ) : (
                <TestimonialRightBox>
                  <RightCard>
                    <TopCard>
                      <Head>
                        <Typography>{card.right.top.heading}</Typography>
                      </Head>
                      <Paragraph>{card.right.top.subtitle}</Paragraph>
                    </TopCard>
                    <BottomCard>
                      <Head>
                        <Typography>{card.right.bottom.heading}</Typography>
                      </Head>
                      <Paragraph>{card.right.bottom.subtitle1}</Paragraph>
                      <Paragraph>{card.right.bottom.subtitle2}</Paragraph>
                    </BottomCard>
                  </RightCard>
                </TestimonialRightBox>
              )}
            </Slide>
          ))}
        </Slider>
      </SliderWrapper>
    </Wrapper>
  );
}

const TopAccordion = styled(Accordion)`
  box-shadow: none;
  margin: 0 !important;
  .MuiAccordion-root:before {
    display: none;
  }

  &:before {
    opacity: 0 !important;
  }
`;

const BottomAccordion = styled(Accordion)`
  box-shadow: none;
  &:before {
    opacity: 0 !important;
  }
`;

const ImageBox = styled.div``;

const StyledAccordionSummary = styled(AccordionSummary)`
  width: 100%;
  padding: ${(props) => props.theme.spacing(0, 2)};
`;

const StyledAccordionDetails = styled(AccordionDetails)`
  padding-top: 0;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(2)}px;
`;

const IndicatorIcon = styled(FiberManualRecordIcon)`
  font-size: 11px;
  &:hover {
    fill: #3c4340;
  }
`;

const Paragraph = styled(Typography)`
  font-size: 10px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0.4px;
  text-align: left;
`;

const RightCard = styled.div`
  width: fill-available;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(3)}px;
  padding: ${({ theme }) => theme.spacing(2)}px;
  ${({ theme }) => theme.breakpoints.up('md')} {
    padding: 0;
    padding-right: 40px;
    width: 489px;
  }
`;

const Subtitle = styled(Typography)`
  font-family: Poppins;
  font-size: 14px;
  line-height: 23.52px;
  font-weight: 400;
  text-align: left;
  ${({ theme }) => theme.breakpoints.up('md')} {
    font-size: 20px;
    line-height: 33.6px;
  }
`;
const Review = styled(Typography)`
  font-family: system-ui;
  font-size: 16px;
  line-height: 27.6px;
  font-weight: 700;
  text-align: left;
  ${({ theme }) => theme.breakpoints.up('md')} {
    font-size: 29px;
    line-height: 50.03px;
  }
`;
const ContactName = styled(Typography)`
  font-family: Poppins;
  font-size: 12px;
  font-weight: 700;
  line-height: 17px;
  letter-spacing: 0.4px;
  text-align: left;
`;
const ContactTitle = styled(Typography)`
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0.4px;
  text-align: left;
`;

const TopCard = styled.div`
  background: #ffffff;
  border-radius: 22px;
  padding: ${({ theme }) => theme.spacing(2)}px;
  display: flex;
  gap: 24px;
  flex-direction: column;
`;

const Head = styled.div`
  p {
    font-size: 16px;
    font-weight: 700;
    line-height: 26.88px;
    text-align: left;
  }
`;
const BottomCard = styled.div`
  padding: 16px;
  gap: ${({ theme }) => theme.spacing(3)}px;
  border-radius: 22px;
  border: 2px solid #9cba6c;
  background: #fff;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(3)}px;
`;

const Card = styled.div`
  width: fill-available;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(2)}px;
  padding: ${({ theme }) => theme.spacing(2)}px;
  ${({ theme }) => theme.breakpoints.up('md')} {
    width: fill-available;
    max-width: 568px;
    padding: 0;
    flex-direction: column;
    padding-left: 80px;
    margin: 85px auto;
  }
`;

const TestimonialLeftBox = styled.div`
  background: #fff;
  flex: 1 1 0px;
`;

const TestimonialRightBox = styled.div`
  flex: 1 1 0px;
  display: flex;
  ${({ theme }) => theme.breakpoints.up('md')} {
    background: #f8f8f8;
  }
`;

const Slide = styled.div`
  display: flex !important;
  margin: 40px 0;
  flex-direction: column;
  box-shadow: 0 4px 40px rgba(62, 41, 29, 0.08);
  margin: auto;
  width: 343px !important;
  min-height: 433px !important;
  ${({ theme }) => theme.breakpoints.up('sm')} {
    margin: 0;
    flex-direction: row;
    width: auto !important;
  }
`;

const SliderWrapper = styled(Box)``;

const Wrapper = styled.div`
  .slick-initialized .slick-slide {
  }
`;

const BackIcon = styled(ArrowBackIosIcon)`
  font-size: 46px !important;
  height: 46px !important;
  width: 46px !important;
  color: #e3e4e4 !important;
  margin-left: 110px;
  z-index: 1;
  &:hover {
    color: #3c4340;
  }
`;

const NextIcon = styled(ArrowBackIosIcon)`
  font-size: 46px !important;
  height: 46px !important;
  width: 46px !important;
  transform: translateY(-50%) rotate(180deg) !important;
  color: #3c4340 !important;
  margin-right: 110px;
  z-index: 1;
  &:hover {
    color: #3c4340 !important;
  }
`;

const Title = styled(Typography)`
  font-weight: 700;
  text-align: center;
  font-size: 20px;
  line-height: 34.5px;
  padding: ${({ theme }) => theme.spacing(0, 4)};
  ${({ theme }) => theme.breakpoints.up('md')} {
    padding: 0;
    font-size: 36px;
    line-height: 62.1px;
  }
`;
